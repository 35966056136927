import React from "react";
import { FooterContainer } from "./Footer_style";

function Footer() {
  return (
    <FooterContainer className="FooterContainer">
      <></>
    </FooterContainer>
  );
}

export default Footer;
