import React from "react";
import {
  HeroSection,
  HeroContainer,
  HeroTitle,
  HeroText,
  CTAButton,
  CTAList,
  CTAListItem,
  CTACard,
  CTATitle,
  HeroSubText,
} from "./Hero.style";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <HeroSection>
      <HeroContainer>
        <HeroTitle>Recover Your Unclaimed Funds</HeroTitle>
        <HeroText>
          We help individuals recover funds from various unclaimed sources and
          provide dedicated support to ensure our clients receive maximum
          entitled funds.
        </HeroText>
        <HeroSubText>
          Are you missing out on funds owed to you? Many individuals are unaware
          of the money they are entitled to. Our team is here to simplify the
          process and help you recover what's rightfully yours.
        </HeroSubText>
        <CTACard>
          <CTATitle>Why Choose Us?</CTATitle>
          <CTAList>
            <CTAListItem>Expertise in asset recovery</CTAListItem>
            <CTAListItem>
              Efficient and thorough verification process
            </CTAListItem>
            <CTAListItem>Seamless paperwork handling</CTAListItem>
            <CTAListItem>
              Dedicated support throughout the recovery process
            </CTAListItem>
          </CTAList>
          <CTAButton onClick={() => navigate("/contact")}>
            Get Started Now
          </CTAButton>
        </CTACard>
      </HeroContainer>
    </HeroSection>
  );
};
export default Hero;
