import styled from "styled-components";
import schema from "../../Global/Setting/GlobalStyle";
export const ContactSection = styled.section`
  padding: 4rem 0;
  background-color: ${schema.lightGray};
  color: ${schema.lightBlack};
  text-align: center;
  height: calc(100vh - 4rem);
`;

export const ContactContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const ContactTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  margin-bottom: 1rem;
`;

export const ContactText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Input = styled.input`
  font-family: "Lato", sans-serif;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid ${schema.gray};
  border-radius: 4px;
`;

export const Textarea = styled.textarea`
  font-family: "Lato", sans-serif;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid ${schema.gray};
  border-radius: 4px;
  resize: vertical;
`;

export const SubmitButton = styled.button`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background-color: ${schema.skyblue};
  color: ${schema.white};
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${schema.darkBlue};
  }
`;

export const ErrorLable = styled.label`
  text-align: left;
  color: red;
`;

export const ErrorMessage = styled.div`
  text-align: left;
  color: red;
  padding: 0 0 1rem 0;
`;
