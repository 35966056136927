import { createBrowserRouter } from "react-router-dom";
import Layout from "../Layout/Layout";
function BrowserRouter(routeList) {
  const routes = [];
  routeList.map((route) =>
    routes.push({ path: route["path"], element: route["element"] })
  );

  const created_Browser_Route = createBrowserRouter([
    {
      path: "/",
      element: <Layout PageRoutes={routeList} />,
      children: routes,
    },
  ]);
  return created_Browser_Route;
}

export default BrowserRouter;
