import React from "react";
import {
  ContactthankyouContainer,
  Header,
  ThankYouMessage,
} from "./Contactthankyou.style";

function Contactthankyou() {
  return (
    <ContactthankyouContainer>
      <Header>Thank you!</Header>
      <ThankYouMessage>
        Thank you for reaching out. We'll get back to you soon.
      </ThankYouMessage>
    </ContactthankyouContainer>
  );
}
export default Contactthankyou;
