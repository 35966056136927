import { l } from "@zufan_devops/console";
import React, { useState } from "react";
import {
  ContactContainer,
  ContactSection,
  ContactTitle,
  ContactText,
  Form,
  Input,
  Textarea,
  SubmitButton,
  ErrorLable,
  ErrorMessage,
} from "./Contact.style";
import httpsRequest from "./libs/httpsRequest";
import validate from "./libs/validate";
import { useNavigate } from "react-router-dom";
export default function Contact() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: { value: "", error: null },
    email: { value: "", error: null },
    message: { value: "", error: null },
  });
  const [errors, setErrors] = useState(null);
  async function handleSubmit(e) {
    e.preventDefault();
    const valid = validate(info, setInfo);
    if (!valid) return;
    const message = {
      name: info.name.value,
      email: info.email.value,
      message: info.message.value,
    };
    const req = await httpsRequest(message);
    if (req.status !== 200 || req.data["message"] !== "success")
      return setErrors(
        "Apologies for the inconvenience. Please try resubmitting. Thank You."
      );
    navigate("/thankyou");
    return;
  }
  return (
    <ContactSection id="contact">
      <ContactContainer>
        <ContactTitle>Contact Us</ContactTitle>
        <ContactText>
          Reach out to us to get started on reclaiming your unclaimed funds!
          Fill out the form below, and our team will get back to you as soon as
          possible.
        </ContactText>
        {errors && <ErrorMessage>{errors}</ErrorMessage>}
        <Form>
          {info.name.error && <ErrorLable>{info.name.error}</ErrorLable>}
          <Input
            type="text"
            placeholder="Your Name"
            required
            onChange={(e) =>
              setInfo({
                ...info,
                name: { ...info.name, value: e.target.value },
              })
            }
            value={info.name.value}
          />
          {info.email.error && <ErrorLable>{info.email.error}</ErrorLable>}
          <Input
            type="email"
            placeholder="Your Email"
            required
            onChange={(e) =>
              setInfo({
                ...info,
                email: { ...info.email, value: e.target.value },
              })
            }
            value={info.email.value}
          />
          {info.message.error && <ErrorLable>{info.message.error}</ErrorLable>}
          <Textarea
            placeholder="Your Message"
            rows="5"
            required
            onChange={(e) =>
              setInfo({
                ...info,
                message: { ...info.message, value: e.target.value },
              })
            }
            value={info.message.value}
          ></Textarea>
          <SubmitButton type="submit" onClick={handleSubmit}>
            Send Message
          </SubmitButton>
        </Form>
      </ContactContainer>
    </ContactSection>
  );
}
