import React, { useEffect, useState } from "react";
import { LayoutContainer, NavigationWrapper } from "./Layout_style";
import { Outlet, useLocation } from "react-router-dom";
//
import Footer from "./Footer/Footer";
import Navigation from "./Navigation/Navigation";

function Layout({ PageRoutes }) {
  const [addPadding, setAddPadding] = useState(false);
  const path = useLocation()["pathname"];
  useEffect(() => {
    setAddPadding(path !== "/");
  }, [path]);
  return (
    <LayoutContainer className="LayoutContainer">
      <NavigationWrapper addpadding={addPadding.toString()}>
        <Navigation routes={PageRoutes} />
      </NavigationWrapper>
      <Outlet />
      <Footer />
    </LayoutContainer>
  );
}
export default Layout;
