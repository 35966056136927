import styled from "styled-components";
import schema from "../../Global/Setting/GlobalStyle";
export const ProcessSection = styled.section`
  padding: 4rem 0;
  background-color: ${schema.white};
  color: ${schema.lightBlack};
  text-align: center;
`;

export const ProcessContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const ProcessTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  margin-bottom: 1rem;
`;

export const ProcessList = styled.ol`
  list-style: none;
  padding: 0;
  counter-reset: step-counter;
`;

export const ProcessItem = styled.li`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin: 1rem 0;
  position: relative;
  padding-left: 2rem;

  &:before {
    content: counter(step-counter);
    counter-increment: step-counter;
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${schema.skyblue};
    color: ${schema.white};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
