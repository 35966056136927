import styled from "styled-components";
import schema from "../../Global/Setting/GlobalStyle";
export const ServicesSection = styled.section`
  padding: 4rem 0;
  background-color: ${schema.lightGray};
  color: ${schema.lightBlack};
  text-align: center;
`;

export const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const ServicesTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  margin-bottom: 1rem;
`;

export const ServicesText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 3rem;
`;

export const ServiceCards = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const ServiceCard = styled.div`
  background: ${schema.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 2rem);
  min-width: 250px;
`;

export const ServiceCardTitle = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin-bottom: 1rem;
`;

export const ServiceCardText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
`;
