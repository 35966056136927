import Services from "../../component/Services/Services";
import Hero from "../../component/Hero/Hero";
import Process from "../../component/Process/Process";
import Contact from "../../component/Contact/Contact";
import Contactthankyou from "../../component/Contactthankyou/Contactthankyou";
// *****  Documention  *****
// private 1=private 0=public -1=private public
// navigation: true= navigationName will displayed in the navigation bar and false= will not
// footer true= navigationName will displayed in the footer and false= will not
// errorComponent true= will only be used if there is error. It for error only be for an error Component
// colipaseNav
/* Not Implemented yet */
// displayRoutesOnThisPage = if true it will display the navigation on that page or  else if false , it will not display the navigation on that page

const PageRoutes = [
  {
    id: "Services",
    navigationName: "Services",
    element: <Services />,
    path: "/Services",
    order: 1,
    private: 1,
    navigation: true,
    footer: false,
    errorComponent: false,
    displayRoutesOnThisPage: true,
    colipaseNav: true,
  },
  {
    id: "Hero",
    navigationName: "Hero",
    element: <Hero />,
    path: "/",
    order: 1,
    private: 1,
    navigation: true,
    footer: false,
    errorComponent: false,
    displayRoutesOnThisPage: !true,
    colipaseNav: true,
  },
  {
    id: "Process",
    navigationName: "Process",
    element: <Process />,
    path: "/Process",
    order: 1,
    private: 1,
    navigation: true,
    footer: false,
    errorComponent: false,
    displayRoutesOnThisPage: true,
    colipaseNav: true,
  },
  {
    id: "Contact",
    navigationName: "Contact",
    element: <Contact />,
    path: "/Contact",
    order: 1,
    private: 1,
    navigation: true,
    footer: false,
    errorComponent: false,
    displayRoutesOnThisPage: true,
    colipaseNav: true,
  },
  {
    id: "Contactthankyou",
    navigationName: "Contactthankyou",
    element: <Contactthankyou />,
    path: "/thankyou",
    order: 1,
    private: 1,
    navigation: true,
    footer: false,
    errorComponent: false,
    displayRoutesOnThisPage: false,
    colipaseNav: true,
  },
];

export default PageRoutes;
