import React from "react";
import { NavigationContainer, Container, Title, Nav } from "./Navigation.style";
import { Link } from "react-router-dom";
const Navigation = ({ routes }) => {
  return (
    <NavigationContainer>
      <Container>
        <Title>Speedy Asset Recovery Firm</Title>
        <Nav>
          <ul>
            {routes.map((route) => {
              if (route["displayRoutesOnThisPage"])
                return (
                  <li key={route.id}>
                    <Link to={route["path"]}>{route["navigationName"]}</Link>
                  </li>
                );
              else return null;
            })}
          </ul>
        </Nav>
      </Container>
    </NavigationContainer>
  );
};
export default Navigation;
