import React from "react";
import {
  ServicesSection,
  ServicesContainer,
  ServicesTitle,
  ServicesText,
  ServiceCards,
  ServiceCard,
  ServiceCardTitle,
  ServiceCardText,
} from "./Services.style";
const Services = () => {
  return (
    <ServicesSection id="services">
      <ServicesContainer>
        <ServicesTitle>Our Services</ServicesTitle>
        <ServicesText>
          We assist individuals in reclaiming funds owed by the government.
        </ServicesText>
        <ServiceCards>
          <ServiceCard>
            <ServiceCardTitle>
              Identifying Eligible Individuals
            </ServiceCardTitle>
            <ServiceCardText>
              We use various methods to find individuals who may be owed money
              by the government.
            </ServiceCardText>
          </ServiceCard>
          <ServiceCard>
            <ServiceCardTitle>Verifying Claims</ServiceCardTitle>
            <ServiceCardText>
              We verify the legitimacy of claims to ensure eligibility.
            </ServiceCardText>
          </ServiceCard>
          <ServiceCard>
            <ServiceCardTitle>Securing Funds</ServiceCardTitle>
            <ServiceCardText>
              We assist clients in completing the necessary paperwork to secure
              funds.
            </ServiceCardText>
          </ServiceCard>
          <ServiceCard>
            <ServiceCardTitle>Distributing Funds</ServiceCardTitle>
            <ServiceCardText>
              Upon successful retrieval, we distribute the funds to the rightful
              owner, minus any service fees.
            </ServiceCardText>
          </ServiceCard>
        </ServiceCards>
      </ServicesContainer>
    </ServicesSection>
  );
};
export default Services;
