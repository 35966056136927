import styled from "styled-components";
import { Link } from "react-router-dom";
export const NavigationContainer = styled.header`
  background-color: #2c3e50;
  color: white;
  padding: 1rem 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Link)`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin: 0;
  text-decoration: none;
  color: white;
`;

export const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;

    li a {
      color: white;
      text-decoration: none;
      font-family: "Lato", sans-serif;
      font-size: 14px;
    }
  }
`;
