// import { l } from "@zufan_devops/console";
export default function validate(info, setInfo) {
  let valid = true;
  const newInfo = { ...info };
  const nameParts = info.name.value.trim().split(" ");

  if (nameParts.length < 2 || nameParts.length > 3) {
    newInfo.name.error = "Please enter both first and last names.";
    valid = false;
  } else if (
    nameParts.length < 2 ||
    nameParts[0].length < 2 ||
    nameParts[1].length < 2
  ) {
    newInfo.name.error = "Please enter both first and last names.";
    valid = false;
  } else if (info.name.value.length > 100) {
    newInfo.name.error = "Name should not exceed 100 characters.";
    valid = false;
  } else {
    newInfo.name.error = "";
  }

  if (!info.email.value) {
    newInfo.email.error = "Email is required";
    valid = false;
  } else if (!/\S+@\S+\.\S+/.test(info.email.value)) {
    newInfo.email.error = "Email is invalid";
    valid = false;
  } else {
    newInfo.email.error = "";
  }

  const message = info.message.value;
  if (!message) {
    newInfo.message.error = "Message is required";
    valid = false;
  } else if (message.length < 3) {
    newInfo.message.error = "Please enter a message";
    valid = false;
  } else {
    newInfo.message.error = "";
  }

  const messageSplit = message.split(" ");
  if (messageSplit.length > 500) {
    newInfo.message.error = "Your message exceed the limit.";
    valid = false;
  } else if (messageSplit.length < 3) {
    newInfo.message.error = "Please enter a message";
    valid = false;
  } else {
    newInfo.message.error = "";
  }
  setInfo(newInfo);
  return valid;
}
