import React from "react";
import {
  ProcessSection,
  ProcessContainer,
  ProcessTitle,
  ProcessList,
  ProcessItem,
} from "./Process.style";

const Process = () => {
  return (
    <ProcessSection id="process">
      <ProcessContainer>
        <ProcessTitle>Our Process</ProcessTitle>
        <ProcessList>
          <ProcessItem>
            Identify potential clients through various methods.
          </ProcessItem>
          <ProcessItem>Verify the legitimacy of claims.</ProcessItem>
          <ProcessItem>
            Assist in completing the necessary paperwork to secure funds.
          </ProcessItem>
          <ProcessItem>
            Distribute the retrieved funds to the rightful owner.
          </ProcessItem>
        </ProcessList>
      </ProcessContainer>
    </ProcessSection>
  );
};
export default Process;
