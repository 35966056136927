// import { ThemeProvider } from "styled-components";
// import createGlobalStyle from "styled-components";

const schema = {
  white: "#fff",
  skyblue: "#2980b9",
  lightGray: "#ecf0f1",
  lightBlack: "#333",
  gray: "#ccc",
  darkBlue: "#1a5d7a",
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export { device };
export default schema;
