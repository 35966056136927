import styled from "styled-components";
import schema from "../../Global/Setting/GlobalStyle";
export const ContactthankyouContainer = styled.div`
  padding: 4rem 0;
  background-color: ${schema.lightGray};
  color: ${schema.lightBlack};
  text-align: center;
  height: 100vh;
  width: 100%;
`;
export const Header = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  margin-bottom: 1rem;
`;

export const ThankYouMessage = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 3rem;
`;
