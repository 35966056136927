import styled from "styled-components";
import schema from "../../Global/Setting/GlobalStyle";
export const HeroSection = styled.section`
  background-color: ${schema.skyblue};
  color: ${schema.white};
  padding: 4rem 0;
  text-align: center;
  height: calc(100vh - 4rem);
  /* overflow: hidden; */
`;

export const HeroContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const HeroTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  margin: 0 0 1rem 0;
`;

export const HeroText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const HeroSubText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 18px;
  margin-bottom: 2rem;
`;

export const CTACard = styled.div`
  background-color: ${schema.white};
  color: ${schema.skyblue};
  padding: 1.5rem;
  border-radius: 8px;
  display: inline-block;
  margin-top: 2rem;
`;

export const CTATitle = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

export const CTAList = styled.ul`
  list-style: none;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const CTAListItem = styled.li`
  margin-bottom: 0.5rem;
`;

export const CTAButton = styled.a`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background-color: ${schema.lightGray};
  color: ${schema.skyblue};
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${schema.skyblue};
    cursor: -webkit-grab;
    cursor: grab;
    color: ${schema.white};
  }
`;
