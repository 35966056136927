import { l } from "@zufan_devops/console";
import axios from "axios";

export default async function httpsRequest(message) {
  try {
    const isProduction = process.env.REACT_APP_PRODUCTION === "true";
    const baseURL = isProduction
      ? "https://3.82.115.233:3001"
      : "http://localhost:3001";
    const req = await axios.request({
      baseURL: baseURL,
      url: "contactUs",
      method: "POST",
      data: {
        name: message.name,
        email: message.email,
        message: message.message,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
}
