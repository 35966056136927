import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const NavigationWrapper = styled.div``;

// padding: ${({ addpadding }) =>
//     addpadding === "true" && "0rem 0rem 4.43rem 0rem"};
